import { get, isEmpty } from 'lodash'
import { put } from 'redux-saga/effects'

import { EXPENSE_HISTORY_TYPES } from 'shared/constants/expenses/expenseHistory'

import { DEFAULT_EXPENSE_CATEGORIES, MONTHS } from 'shared/constants/expenses'

import { errorNotification } from '../../../../../shared/redux/actions/notifications'

import * as Api from '../../../../api'

function mapExpenseItems(items) {
  const expenses = {}
  for (const category in items) {
    const total = get(items, `${category}.total`, null)
    const mappedExpenseItem = {
      total: total,
      psf: get(items, `${category}.sf`, null),
      perUnit: get(items, `${category}.unit`, null),
      reported: get(items, `${category}.reported`, !!total),
    }
    expenses[category] = mappedExpenseItem
  }

  Object.keys(DEFAULT_EXPENSE_CATEGORIES).forEach(expenseKey => {
    if (!(expenseKey in expenses)) {
      const item = {
        total: null,
        psf: null,
        perUnit: null,
        reported: false,
      }
      expenses[expenseKey] = item
    }
  })
  return expenses
}

function mapExpensePeriod(period) {
  if (!period) {
    return null
  }
  return EXPENSE_HISTORY_TYPES[period] || period
}

function mapFetchedExpenseComps(expenseComps) {
  const mappedExpenseComps = expenseComps.map(expenseComp => {
    const _id = get(expenseComp, '_id', null)
    const yearBuilt = get(expenseComp, 'yearBuilt', null)
    const residentialUnits = get(expenseComp, 'numberOfResidentialUnits', null)
    const squareFeet = get(expenseComp, 'gba', null)
    const items = get(expenseComp, 'expenses.0', null)
    const expensePeriod = mapExpensePeriod(get(expenseComp, 'expensePeriod'))
    const expenseYear = get(expenseComp, 'expenseYear', null)
    const expenseMonth = get(expenseComp, 'expenseMonth', null) ? MONTHS[expenseComp.expenseMonth] : null
    const expenseDate = get(expenseComp, 'expenseDate', null)
    const expensesTotalGross = {
      psf: get(expenseComp, 'totalExpensesGross.sf', null),
      perUnit: get(expenseComp, 'totalExpensesGross.unit', null),
      total: get(expenseComp, 'totalExpensesGross.total', null),
    }
    const expensesTotalNet = {
      psf: get(expenseComp, 'totalExpensesNet.sf', null),
      perUnit: get(expenseComp, 'totalExpensesNet.unit', null),
      total: get(expenseComp, 'totalExpensesNet.total', null),
    }
    const address = expenseComp.streetAddress
    const location = expenseComp.city
    const state = expenseComp.state
    const expenses = mapExpenseItems(items)
    const commercialUnits = get(expenseComp, 'numberOfCommercialUnits')
    const buildingType = get(expenseComp, 'buildingType')
    const sourceOfInformation = get(expenseComp, 'sourceOfInformation', null)
    const sourceName = get(expenseComp, 'sourceName', null)
    const sourceUrl = get(expenseComp, 'sourceUrl', null)
    const expenseHistoryReportId = get(expenseComp, 'expenseHistoryReportId', null)
    const approved = get(expenseComp, 'isApproved', null)
    const updatedAt = get(expenseComp, 'updatedAt', null)
    const createdAt = get(expenseComp, 'createdAt', null)
    const manuallyCreated = get(expenseComp, 'manuallyCreated', null)

    return {
      _id,
      boweryId: _id,
      location,
      address,
      yearBuilt,
      residentialUnits,
      commercialUnits,
      squareFeet,
      sourceOfInformation,
      sourceName,
      sourceUrl,
      expenseHistoryReportId,
      approved,
      updatedAt,
      createdAt,
      expenses,
      expensesTotalGross,
      expensesTotalNet,
      expenseYear,
      expenseMonth,
      expensePeriod,
      expenseDate,
      buildingType,
      manuallyCreated,
      state,
      propertyType: get(expenseComp, 'buildingType'),
      effectiveGrossIncome: get(expenseComp, 'effectiveGrossIncome'),
      grossRevenue: get(expenseComp, 'grossRevenue'),
      version: get(expenseComp, 'version'),
    }
  })

  return mappedExpenseComps
}

export function mapExpenseComps(checkedComps, expenseComps) {
  const mappedExpenseComps = expenseComps.map(expenseComp => {
    const isSelected = checkedComps.some(
      selectedExpenseComp =>
        selectedExpenseComp._id === expenseComp._id || selectedExpenseComp.boweryId === expenseComp.boweryId
    )
    return {
      ...expenseComp,
      isSelected,
    }
  })
  return mappedExpenseComps
}

const removeEmpty = filters => {
  Object.keys(filters).forEach(key => {
    const obj = filters[key]
    if ('min' in obj && obj.min === '') {
      delete obj.min
    }
    if ('max' in obj && obj.max === '') {
      delete obj.max
    }
    if (isEmpty(obj)) {
      delete filters[key]
    }
  })
}

const mapToMongoFields = field => {
  switch (field) {
    case 'address':
      return 'streetAddress'
    case 'squareFeet':
    case 'gba':
      return 'gba'
    case 'expenseYear':
      return 'expenseYear'
    case 'totalOperatingExpenses':
    case 'totalOperatingExpenses.sf':
      return 'totalPerSqft'
    case 'totalOperatingExpenses.unit':
    case 'totalOperatingExpensesPerUnit':
      return 'totalPerUnit'
    case 'residentialUnits':
      return 'numberOfResidentialUnits'
    case 'type':
      return 'buildingType'
    default:
      return field
  }
}

export async function fetchExpenseComps(payload) {
  try {
    const { checkedComps, tableFilters, reportId, options } = payload
    const { pageSize, currentPage, locations, state, expensePeriod, unitsCount, type } = tableFilters

    let { sortField, sortDirection } = payload.tableFilters

    const selectedFilters = payload.filters || {}

    const mappedLocations = locations.map(location => {
      return location === 'Manhattan' ? 'New York' : location
    })

    const filters = {
      locations: mappedLocations,
      state,
    }

    removeEmpty(selectedFilters)

    sortField = mapToMongoFields(sortField)
    sortDirection = sortDirection.toUpperCase()

    const itemFilters = []
    for (const param in selectedFilters) {
      let currentObject
      switch (param) {
        case 'expenseYear':
          filters['date'] = {
            min: selectedFilters[param].min ? new Date(selectedFilters[param].min, 0, 1) : null,
            max: selectedFilters[param].max ? new Date(selectedFilters[param].max, 11, 31, 23, 59, 59) : null,
          }
          break
        case 'totalOperatingExpenses':
          filters['totalPerSqft'] = selectedFilters[param]
          break
        case 'totalOperatingExpensesPerUnit':
          filters['totalPerUnit'] = selectedFilters[param]
          break
        case 'residentialUnits':
          filters['residentialUnitCount'] = selectedFilters[param]
          break
        case 'yearBuilt':
          filters['yearBuilt'] = selectedFilters[param]
          break
        case 'squareFeet':
          filters['gba'] = selectedFilters[param]
          break
        default:
          currentObject = { key: param, value: selectedFilters[param] }
          itemFilters.push(currentObject)
          break
      }
    }
    filters.itemFilters = itemFilters

    if (state) {
      filters.state = state
    }
    if (unitsCount) {
      filters.unitsCount = unitsCount
    }
    if (expensePeriod) {
      filters.period = expensePeriod
    }
    if (type) {
      filters.use = type
    }
    filters.addedExpenses = get(payload, 'comparableExpenses', [])

    const result = await Api.fetchExpenseCompsFromCompPlex(
      reportId,
      { ...options, ...filters },
      sortField,
      sortDirection,
      pageSize,
      currentPage
    )

    const availableLocations = result.availableLocations.map(location => {
      return location === 'New York' ? 'Manhattan' : location
    })
    const availableStates = result.availableStates

    const mappedFetchedExpenseComps = mapFetchedExpenseComps(result.expenseComps)
    const mappedExpenseComps = mapExpenseComps(checkedComps, mappedFetchedExpenseComps)

    return { ...result, availableLocations, availableStates, expenseComps: mappedExpenseComps }
  } catch (err) {
    put(errorNotification({ message: `${err}` }))
  }
}
