import React, { createRef } from 'react'
import { withRouter, matchPath } from 'react-router'
import { differenceWith, eq, get, pick, findIndex } from 'lodash'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import {
  AppBar,
  Toolbar,
  withStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core'
import ExpandMore from '@mui/icons-material/ExpandMore'

import PropTypes from 'prop-types'
import ObjectId from 'bson-objectid/objectid'
import { Button, Paper, Stack, Grid, Typography } from '@mui/material'

import SelectField from 'client-shared/components/_mui5/Select/select-field'
import {
  UNIT_OF_COMPARISON_ITEMS,
  SALES_APPROACH_TYPE_DISPLAY,
  SALES_APPROACH_TYPES,
} from 'shared/constants/salesApproach'
import { Area, Template } from 'client-shared/components/Template'
import { checkIfCompsAreLatestVersion } from 'report/forms/sales/SalesCompsSearch/helpers'
import ImportSalesComps from 'report/forms/sales/SalesCompsSearch/ImportSalesComps'
import AutomationWarnings from 'client-shared/components/AutomationCTA/AutomationWarnings'

import { LAND_COMPS_SEARCH_PATH } from 'shared/constants/report/keysAndDataPaths'

import { withMicrofrontend } from '../../../../shared/hooks/useMicrofrontend'
import { CompPlexInputEvents, CompPlexEvents } from '../../../../shared/constants/compPlex'
import { errorNotification } from '../../../../shared/redux/actions/notifications'
import { findCompBySaleDateAndAddress } from '../../../../shared/utils/compPlex.gql'
import StickyFormTopPanel from '../../../layout/StickyFormTopPanel'
import { getAuthorizationHeader } from '../../../../core/api'

import { saveReport } from '../../../redux/actions/report'

import AdjustmentAutomationWarning from '../SalesAdjustmentGrid/AdjustmentAutomationWarning'

import { selectReportInformation } from '../../property/Zoning/selectors'

import { areSameComps } from '../tools'

import { transformSalesCompRawInput, transformSalesCompToRawOutput } from './CreateSalesComp/drm/helpers/transformers'
import SalesCompsAutomation from './SalesCompsAutomation'
import CreateSalesComp from './CreateSalesComp'
import SelectedSalesCompsTable from './SelectedSalesCompsTable'
import SelectedLandCompsTable from './SelectedLandCompsTable'
import RemovedSalesCompsTable from './RemovedSalesCompsTable'
import SalesCompsIncomeApproachConclusion from './SalesCompsIncomeApproachConclusion'
import SelectedSalesCompsStatistics from './SelectedSalesCompsStatistics'
import { getSelectedSalesComps, getRemovedSalesComps } from './tools'

const SALES_COMPARABLES = 'Selected Comps'
const REMOVED_SALES_COMPARABLES = 'Removed Comps'
export const EDIT_COMP_SOURCE_LOCATION = 'location'
export const EDIT_COMP_SOURCE_UPDATE_FAILED = 'update-failed'

const styles = theme => ({
  appBar: {
    paddingTop: 8,
    paddingBottom: 8,
    boxShadow: 'none',
  },
  appSection: {
    paddingTop: 12,
  },
  addManuallyWrapper: {
    padding: 16,
  },
  form: {
    minWidth: 1360,
    width: '100%',
  },
  expansionPanel: {
    cursor: 'default !important',
  },
  expansionPanelIcon: {
    cursor: 'pointer',
  },
})

class SalesCompsSearchContainer extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    selectedCompIds: PropTypes.arrayOf(PropTypes.object).isRequired,
    subjectProperty: PropTypes.object.isRequired,
    isLandSearch: PropTypes.bool.isRequired,
    propertyInformation: PropTypes.object.isRequired,
    subjectCoordinates: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }).isRequired,
    authenticatedUser: PropTypes.object.isRequired,
    reportId: PropTypes.string.isRequired,
    saveReport: PropTypes.func.isRequired,
    errorNotification: PropTypes.func.isRequired,
    formPath: PropTypes.string.isRequired,
    compPlexLoaded: PropTypes.bool.isRequired,
  }

  state = {
    editCompProps: null,
    initialLocalSalesComp: null,
    expanded: true,
    snapshotRequested: false,
  }

  constructor(props) {
    super(props)
    this.compPlexRef = createRef()
  }

  getCompIdToEditFromLocation() {
    const {
      match: { path },
      location,
    } = this.props
    const match = matchPath(location.pathname, { path: `${path}/details/:salesCompId` })
    return match ? match.params.salesCompId : null
  }

  getLocalCompToEditFromLocation() {
    const { selectedComps } = this.props
    const salesCompId = this.getCompIdToEditFromLocation()
    if (!salesCompId) {
      return null
    }
    return selectedComps.find(salesComp => salesCompId === salesComp._id) || null
  }

  getEditCompPropsFromLocation() {
    const { location } = this.props
    const localSalesComp = this.getLocalCompToEditFromLocation()
    const params = new URLSearchParams(location.search)
    const tab = params.get('select_tab')
    const openActivityTab = tab === 'activity'
    if (!localSalesComp) {
      return null
    }
    const { salesTransactionId, salesTransactionVersion } = localSalesComp

    if (salesTransactionId && salesTransactionVersion) {
      return {
        source: EDIT_COMP_SOURCE_LOCATION,
        salesTransactionId,
        salesTransactionVersion,
        requireLatest: true,
        openActivityTab,
      }
    } else {
      return {
        source: EDIT_COMP_SOURCE_LOCATION,
        salesComp: transformSalesCompRawInput(localSalesComp),
        requireLatest: true,
        openActivityTab,
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props

    if (location !== prevProps.location) {
      const navigatedToEditModal = !!this.getCompIdToEditFromLocation()
      if (navigatedToEditModal) {
        const isCompToEditValid = !!this.getLocalCompToEditFromLocation()
        if (!isCompToEditValid) {
          this.navigateAwayFromEditModal()
        }
      }
    }
  }

  componentDidMount() {
    const { compPlexLoaded, errorNotification } = this.props
    if (!compPlexLoaded) {
      errorNotification('Failed to load sales comps service. Some features will not be available.')
    }

    if (this.compPlexRef.current) {
      this.compPlexRef.current.addEventListener(CompPlexEvents.EDIT_COMP_UPDATED, this.handleEditModalUpdated)
      this.compPlexRef.current.addEventListener(
        CompPlexEvents.EDIT_COMP_UPDATE_FAILED,
        this.handleEditModalUpdateFailed
      )
      this.compPlexRef.current.addEventListener(CompPlexEvents.EDIT_COMP_SAVED, this.handleEditModalSaved)
      this.compPlexRef.current.addEventListener(CompPlexEvents.EDIT_COMP_COMPLETED, this.handleEditModalCompleted)
      this.compPlexRef.current.addEventListener(
        CompPlexEvents.EDIT_COMP_SELECT_TOGGLED,
        this.handleEditModalSelectToggled
      )
      this.compPlexRef.current.addEventListener(CompPlexEvents.EDIT_COMP_CLOSED, this.handleEditModalClosed)

      this.compPlexRef.current.addEventListener(CompPlexEvents.IMPORT_CSV_COMPLETED, this.handleImportCsvCompleted)

      this.compPlexRef.current.addEventListener(CompPlexEvents.ERROR, this.handleError)

      this.compPlexRef.current.addEventListener(CompPlexEvents.MAP_COMP_ADDED, this.handleMapCompAdded)
      this.compPlexRef.current.addEventListener(CompPlexEvents.MAP_COMP_REMOVED, this.handleMapCompRemoved)
      this.compPlexRef.current.addEventListener(
        CompPlexEvents.MAP_SEARCH_RESULTS_UPDATED,
        this.handleMapSearchResultsUpdated
      )
    }

    const navigatedToEditModal = !!this.getCompIdToEditFromLocation()
    if (navigatedToEditModal) {
      const isCompToEditValid = !!this.getLocalCompToEditFromLocation()
      if (!isCompToEditValid) {
        this.navigateAwayFromEditModal()
      }
    }
  }

  componentWillUnmount() {
    if (this.compPlexRef.current) {
      this.compPlexRef.current.removeEventListener(CompPlexEvents.EDIT_COMP_UPDATED, this.handleEditModalUpdated)
      this.compPlexRef.current.removeEventListener(
        CompPlexEvents.EDIT_COMP_UPDATE_FAILED,
        this.handleEditModalUpdateFailed
      )
      this.compPlexRef.current.removeEventListener(CompPlexEvents.EDIT_COMP_SAVED, this.handleEditModalSaved)
      this.compPlexRef.current.removeEventListener(CompPlexEvents.EDIT_COMP_COMPLETED, this.handleEditModalCompleted)
      this.compPlexRef.current.removeEventListener(
        CompPlexEvents.EDIT_COMP_SELECT_TOGGLED,
        this.handleEditModalSelectToggled
      )
      this.compPlexRef.current.removeEventListener(CompPlexEvents.EDIT_COMP_CLOSED, this.handleEditModalClosed)

      this.compPlexRef.current.addEventListener(CompPlexEvents.IMPORT_CSV_COMPLETED, this.handleImportCsvCompleted)

      this.compPlexRef.current.removeEventListener(CompPlexEvents.ERROR, this.handleError)

      this.compPlexRef.current.removeEventListener(CompPlexEvents.MAP_COMP_ADDED, this.handleMapCompAdded)
      this.compPlexRef.current.removeEventListener(CompPlexEvents.MAP_COMP_REMOVED, this.handleMapCompRemoved)
      this.compPlexRef.current.removeEventListener(
        CompPlexEvents.MAP_SEARCH_RESULTS_UPDATED,
        this.handleMapSearchResultsUpdated
      )
    }
  }

  showEditModal = (editCompProps, initialLocalSalesComp) => {
    this.setState({ editCompProps, initialLocalSalesComp })
  }

  hideEditModal = () => {
    this.setState({ editCompProps: null, initialLocalSalesComp: null })
  }

  navigateToEditModal = (compId, openActivityTab = false) => {
    const {
      history,
      match: { path },
    } = this.props
    history.push(`${path}/details/${compId}${openActivityTab ? '?select_tab=activity' : ''}`, { skipSave: true })
  }

  navigateAwayFromEditModal = () => {
    const {
      history,
      match: { path },
    } = this.props

    if (this.getCompIdToEditFromLocation()) {
      history.push(path, { skipSave: true })
    }
  }

  handleEditModalUpdated = event => {
    const { source, salesComp } = event.detail
    if (source === EDIT_COMP_SOURCE_LOCATION) {
      const initialLocalSalesComp = this.getLocalCompToEditFromLocation()
      this.saveEditedComp(salesComp, initialLocalSalesComp)
    }
  }

  handleEditModalUpdateFailed = event => {
    const { source, salesComp } = event.detail
    if (source !== EDIT_COMP_SOURCE_LOCATION) {
      return
    }
    const initialLocalSalesComp = this.getLocalCompToEditFromLocation()
    this.navigateAwayFromEditModal()

    this.showEditModal(
      {
        source: EDIT_COMP_SOURCE_UPDATE_FAILED,
        salesComp,
        creating: true,
      },
      initialLocalSalesComp
    )
  }

  handleEditModalSaved = event => {
    const { source, salesComp } = event.detail
    if (source !== EDIT_COMP_SOURCE_LOCATION) {
      return
    }
    const initialLocalSalesComp = this.getLocalCompToEditFromLocation()
    this.saveEditedComp(salesComp, initialLocalSalesComp)
  }

  handleEditModalCompleted = event => {
    const { source, salesComp } = event.detail
    if (source === EDIT_COMP_SOURCE_LOCATION) {
      const initialLocalSalesComp = this.getLocalCompToEditFromLocation()
      this.saveEditedComp(salesComp, initialLocalSalesComp)
      this.navigateAwayFromEditModal()
    } else if (source === EDIT_COMP_SOURCE_UPDATE_FAILED) {
      this.saveEditedComp(salesComp, this.state.initialLocalSalesComp)
      this.hideEditModal()
    }
  }

  handleEditModalSelectToggled = event => {
    const { source, salesComp } = event.detail
    if (source !== EDIT_COMP_SOURCE_LOCATION) {
      return
    }

    const initialLocalSalesComp = this.getLocalCompToEditFromLocation()
    const localSalesComp = transformSalesCompToRawOutput(salesComp, initialLocalSalesComp)
    this.removeComp(localSalesComp)
    this.navigateAwayFromEditModal()
  }

  handleEditModalClosed = event => {
    const { source } = event.detail
    if (source === EDIT_COMP_SOURCE_LOCATION) {
      this.navigateAwayFromEditModal()
    } else if (source === EDIT_COMP_SOURCE_UPDATE_FAILED) {
      this.hideEditModal()
    }
  }

  handleMapCompAdded = async event => {
    const { salesComp } = event.detail
    const localSalesComp = transformSalesCompToRawOutput(salesComp)
    await this.addComp(localSalesComp)
  }

  handleMapCompRemoved = event => {
    const { salesTransactionId } = event.detail
    this.removeComp({ salesTransactionId })
  }

  handleMapSearchResultsUpdated = event => {
    const { salesComp } = event.detail
    const localSalesComp = transformSalesCompToRawOutput(salesComp)
    this.syncCompsWithEditedSearchResult(localSalesComp)
  }

  handleError = event => {
    const { error } = event.detail
    this.props.errorNotification(error.message || error)
  }

  handleCsvImportClick = () => {
    if (this.compPlexRef.current) {
      this.compPlexRef.current.dispatchEvent(new CustomEvent(CompPlexInputEvents.IMPORT_CSV))
    }
  }

  handleImportCsvCompleted = async event => {
    try {
      const { salesComps } = event.detail
      const { form, reportId } = this.props

      const selectedSalesComps = get(form, 'values.selectedComps', [])
      const localComps = salesComps.map(salesComp => {
        const localSalesComp = transformSalesCompToRawOutput(salesComp)
        return {
          ...localSalesComp,
          _id: ObjectId().toString(),
          report: { _id: reportId },
        }
      })

      const updatedSelectedSaleComps = await this.updateSelectedSalesComps(localComps)
      const newOrUpdatedComps = differenceWith(updatedSelectedSaleComps, selectedSalesComps, eq)

      return newOrUpdatedComps
    } catch (error) {
      console.error('Import comps failure', error)
    }
  }

  updateSelectedSalesComps = async newSelectedSalesComps => {
    const { form } = this.props

    const selectedSalesComps = get(form, 'values.selectedComps', [])
    const removedSalesComps = get(form, 'values.removedComps', [])
    const updatedSelectedSalesComps = getSelectedSalesComps(selectedSalesComps, newSelectedSalesComps)
    const updatedRemovedSalesComps = getRemovedSalesComps(removedSalesComps, newSelectedSalesComps)

    const selectedCompsWithLatestInfo = await checkIfCompsAreLatestVersion(updatedSelectedSalesComps)

    form.batch(() => {
      form.change('selectedComps', selectedCompsWithLatestInfo)
      form.change('removedComps', updatedRemovedSalesComps)
    })

    return updatedSelectedSalesComps
  }

  addComp = async newSaleComp => {
    await this.updateSelectedSalesComps([newSaleComp])
  }

  addImportedSalesComps = async localImportedSalesComps => {
    try {
      const { form, reportId } = this.props

      const selectedSalesComps = get(form, 'values.selectedComps', [])
      const updates = localImportedSalesComps.map(async localImportedSalesComp => {
        const localSalesComp = await this.getLocalCompToImport(localImportedSalesComp)
        return {
          ...localSalesComp,
          _id: ObjectId().toString(),
          report: { _id: reportId },
        }
      })
      const updatedImportedComps = await Promise.all(updates)

      const updatedSelectedSaleComps = await this.updateSelectedSalesComps(updatedImportedComps)
      const newOrUpdatedComps = differenceWith(updatedSelectedSaleComps, selectedSalesComps, eq)

      return newOrUpdatedComps
    } catch (error) {
      console.error('Import comps failure', error)
    }
  }

  getLocalCompToImport = async localImportedSalesComp => {
    if (localImportedSalesComp.salesTransactionId) {
      return localImportedSalesComp
    }

    const salesComp = transformSalesCompRawInput(localImportedSalesComp)
    const foundComp = await findCompBySaleDateAndAddress(
      {
        address: salesComp.address,
        saleDate: salesComp.saleDate,
      },
      true
    )

    console.warn('Importing sales comp without salesTransactionId', localImportedSalesComp, foundComp)

    if (foundComp) {
      return {
        ...localImportedSalesComp,
        salesTransactionId: foundComp.id,
      }
    }

    return localImportedSalesComp
  }

  removeComp = comp => {
    const { form } = this.props

    const selectedComps = get(form, 'values.selectedComps', [])
    const removedComps = get(form, 'values.removedComps', [])

    const compIndex = findIndex(selectedComps, selectedComp => areSameComps(comp, selectedComp))
    const currentComp = selectedComps[compIndex]

    // total removal of a comp
    const newCollection = [...selectedComps.slice(0, compIndex), ...selectedComps.slice(compIndex + 1)]
    const updatedRemovedSalesComps = [...removedComps, currentComp]

    form.batch(() => {
      form.change('selectedComps', newCollection)
      form.change('removedComps', updatedRemovedSalesComps)
    })
  }

  saveEditedComp = (updatedSalesComp, initialLocalSalesComp) => {
    const { form } = this.props

    if (!initialLocalSalesComp) {
      return
    }

    const updatedLocalSalesComp = transformSalesCompToRawOutput(updatedSalesComp, initialLocalSalesComp)

    const selectedComps = get(form, 'values.selectedComps', [])
    const compToEditIndex = selectedComps.findIndex(selectedComp => areSameComps(updatedLocalSalesComp, selectedComp))
    form.change(`selectedComps.${compToEditIndex}`, updatedLocalSalesComp)

    if (!!updatedSalesComp.deletedAt) {
      return
    }

    this.props.saveReport(this.props.formPath)
  }

  deleteRemovedComp = comp => {
    const { form } = this.props
    const removedComps = get(form, 'values.removedComps', [])

    const compIndex = findIndex(removedComps, removedComp => areSameComps(comp, removedComp))
    if (compIndex === -1) {
      return
    }

    const updatedCollection = [...removedComps.slice(0, compIndex), ...removedComps.slice(compIndex + 1)]
    form.change('removedComps', updatedCollection)
  }

  syncCompsWithEditedSearchResult = searchResult => {
    const { form } = this.props
    const selectedComps = get(form, 'values.selectedComps', [])
    const removedComps = get(form, 'values.removedComps', [])

    const outdatedSelectedCompIndex = selectedComps.findIndex(
      salesComp => salesComp.salesTransactionId === searchResult.salesTransactionId
    )
    if (outdatedSelectedCompIndex >= 0) {
      form.change(`selectedComps.${outdatedSelectedCompIndex}`, searchResult)
      return
    }

    const outdatedRemovedCompIndex = removedComps.findIndex(
      salesComp => salesComp.salesTransactionId === searchResult.salesTransactionId
    )
    if (outdatedRemovedCompIndex >= 0) {
      form.change(`removedSalesComps.${outdatedRemovedCompIndex}`, searchResult)
    }
  }

  clearRemovedTable = () => {
    const { form } = this.props
    form.change('removedComps', [])
  }
  toggleExpansionPanel = () => {
    const { expanded } = this.state
    this.setState({ expanded: !expanded })
  }

  getApproachTypeLabel = () => {
    const {
      form: {
        values: { type },
      },
    } = this.props
    return SALES_APPROACH_TYPE_DISPLAY[type]
  }

  render() {
    const {
      selectedCompIds,
      subjectProperty,
      propertyInformation,
      subjectCoordinates,
      classes,
      form,
      authenticatedUser,
      reportId,
      formPath,
      saveReport,
      isLandSearch,
    } = this.props
    const { editCompProps: editCompPropsFromState, expanded } = this.state
    const subjectProps = {
      coordinates: subjectCoordinates,
      propertyType: subjectProperty.type,
      marketAnalysisUses: propertyInformation.propertyMarket?.marketAnalysisUses || {},
      residentialUnits: subjectProperty.residentialUnits,
      grossBuildingArea: subjectProperty.gba,
    }

    const { reportNumber } = form.values
    const reportData = { jobNumber: reportNumber, reportId }

    const selectedComps = get(form, 'values.selectedComps', [])
    const sortBy = get(form, 'values.sortBy')
    const removedComps = get(form, 'values.removedComps', [])
    const filters = get(form, 'values.filters')
    const salesApproachType = get(form, 'values.type')
    const unitOfComparison = get(form, 'values.unitOfComparison')

    const selectedSalesCompCount = selectedComps.length
    const removedSalesCompCount = removedComps.length

    const rentDenomination = get(filters, 'rentDenomination', null)

    const removedTableDisabled = removedSalesCompCount === 0

    const editCompProps = this.getEditCompPropsFromLocation() || editCompPropsFromState

    return (
      <div className={classes.form}>
        <StickyFormTopPanel>
          <AppBar className={classes.appBar} position="static" color="default">
            <Toolbar>
              <Grid container>
                <Grid item xs={6} lg={6}>
                  <div className={classes.appSection}>
                    <SalesCompsIncomeApproachConclusion />
                  </div>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <div className={classes.appSection}>
                    <SelectedSalesCompsStatistics selectedComps={selectedComps} rentDenomination={rentDenomination} />
                  </div>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </StickyFormTopPanel>
        <Paper>
          <AutomationWarnings
            warningMessage={
              <AdjustmentAutomationWarning
                selectedComps={selectedComps}
                reportId={reportId}
                showEditModal={initialLocalSalesComp => {
                  this.navigateToEditModal(initialLocalSalesComp._id)
                }}
              />
            }
            warningTitle="Some adjustments cannot not be automated on the Adjust Comps page"
          />
        </Paper>
        <SalesCompsAutomation
          isLandSearch={isLandSearch}
          compPlexRef={this.compPlexRef}
          saveReport={saveReport}
          formPath={formPath}
          subjectProperty={subjectProperty}
          updateSelectedSalesComps={this.updateSelectedSalesComps}
        />
        <Grid item lg={12}>
          <Paper>
            <Template is={`'title options'`} gap={2}>
              <Grid item container direction="column">
                <Area is="title">
                  <Typography variant="h6" gutterBottom>
                    AI Sales Comps
                  </Typography>
                </Area>
              </Grid>
            </Template>
          </Paper>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <Paper>
              <Template is={`'title options'`} gap={2}>
                <Grid item container direction="column">
                  <Area is="title">
                    <Typography variant="h6" gutterBottom>
                      Sale Comparables Setup
                    </Typography>
                  </Area>
                  <Area is="options">
                    <Grid item container direction="row" spacing={2}>
                      <Grid item>
                        <Typography variant="subtitle1">Sales Approach Type</Typography>
                        <Typography variant="body1">{this.getApproachTypeLabel()}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">Basis for Comparison</Typography>
                        <SelectField name="unitOfComparison" options={UNIT_OF_COMPARISON_ITEMS} />
                      </Grid>
                    </Grid>
                  </Area>
                </Grid>
              </Template>
            </Paper>
          </Grid>
          <Grid item lg={12}>
            <ExpansionPanel defaultExpanded expanded={expanded}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                IconButtonProps={{
                  onClick: this.toggleExpansionPanel,
                  className: classes.expansionPanelIcon,
                }}
                className={classes.expansionPanel}
              >
                <Stack alignItems="center" direction="row" spacing={2}>
                  <Typography variant="h6">Comp Database</Typography>
                  {this.compPlexRef.current && (
                    <CreateSalesComp
                      addComp={this.addComp}
                      compPlexRef={this.compPlexRef}
                      editCompProps={editCompPropsFromState}
                      showEditModal={this.showEditModal}
                      hideEditModal={this.hideEditModal}
                    />
                  )}
                  {this.compPlexRef.current && (
                    <Button
                      color="primary"
                      onClick={this.handleCsvImportClick}
                      sx={{ height: 30 }}
                      variant="outlined"
                      data-qa="csv-upload-btn"
                    >
                      CSV UPLOAD
                    </Button>
                  )}
                  {this.compPlexRef.current && (
                    // ImportSalesComps does not render anything.
                    <ImportSalesComps compPlexRef={this.compPlexRef} importComps={this.addImportedSalesComps} />
                  )}
                </Stack>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <comp-plex
                  ref={this.compPlexRef}
                  edit-comp-props={JSON.stringify(editCompProps)}
                  subject={JSON.stringify(subjectProps)}
                  auth-user={JSON.stringify(authenticatedUser)}
                  webapp-auth-header={getAuthorizationHeader().Authorization}
                  webapp-api-url={global.env.apiUrl}
                  subject-coordinates={JSON.stringify(subjectCoordinates)}
                  selected-comps={JSON.stringify(selectedCompIds)}
                  meta-data={JSON.stringify(reportData)}
                  unit-of-comparison={unitOfComparison}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item lg={12}>
            {salesApproachType === SALES_APPROACH_TYPES.IMPROVED ? (
              <SelectedSalesCompsTable
                title={`${SALES_COMPARABLES} (${selectedSalesCompCount})`}
                addComp={this.addComp}
                removeComp={this.removeComp}
                editCompProps={editCompPropsFromState}
                showEditModal={this.showEditModal}
                hideEditModal={this.hideEditModal}
                navigateToEditModal={this.navigateToEditModal}
                salesComps={selectedComps}
                sortBy={sortBy}
                subjectProperty={subjectProperty}
                subjectCoordinates={subjectCoordinates}
                form={form}
                authenticatedUser={authenticatedUser}
                fieldName="selectedComps"
              />
            ) : (
              <SelectedLandCompsTable
                title={`${SALES_COMPARABLES} (${selectedSalesCompCount})`}
                addComp={this.addComp}
                removeComp={this.removeComp}
                editCompProps={editCompPropsFromState}
                showEditModal={this.showEditModal}
                hideEditModal={this.hideEditModal}
                navigateToEditModal={this.navigateToEditModal}
                salesComps={selectedComps}
                sortBy={sortBy}
                subjectProperty={subjectProperty}
                subjectCoordinates={subjectCoordinates}
                form={form}
                authenticatedUser={authenticatedUser}
                fieldName="selectedComps"
                unitOfComparison={unitOfComparison}
              />
            )}
          </Grid>
          <Grid item lg={12}>
            <RemovedSalesCompsTable
              title={`${REMOVED_SALES_COMPARABLES} (${removedSalesCompCount})`}
              addComp={this.addComp}
              removeComp={this.deleteRemovedComp}
              removedComps={removedComps}
              subjectProperty={subjectProperty}
              subjectCoordinates={subjectCoordinates}
              disabled={removedTableDisabled}
              clearRemovedTable={this.clearRemovedTable}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { formPath } = get(state, 'shared.location.form', {})
  const selectedComps = get(ownProps, 'form.values.selectedComps') || []

  const concludedCapRate = get(state, 'report.reportData.incomeApproach.capRateConclusion.concludedCapRate')
  const coords = get(state, 'report.reportData.propertyInformation.coords')
  const propertySummary = get(state, 'report.reportData.propertyInformation.propertySummary')

  const reportInformation = selectReportInformation(state)

  return {
    formPath,
    subjectProperty: {
      coords,
      propertySummary,
      concludedCapRate,
      isNYCReport: reportInformation.isNYCReport,
    },
    isLandSearch: formPath === LAND_COMPS_SEARCH_PATH.join('.'),
    selectedComps,
    selectedCompIds: selectedComps.map(salesComp => {
      return {
        salesTransactionId: salesComp.salesTransactionId,
      }
    }),
    authenticatedUser: pick(get(state, 'authentication.user'), ['id', 'username', 'fullName']),
    reportId: get(state, 'report.reportData._id'),
  }
}

const mapDispatchToProps = dispatch => ({
  errorNotification: error => dispatch(errorNotification({ message: error })),
  saveReport: formPath => dispatch(saveReport(formPath)),
})

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withMicrofrontend('compplex', 'compPlexLoaded')
)(SalesCompsSearchContainer)
