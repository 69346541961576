import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, Typography } from '@mui/material'

import Text from 'client-shared/components/Text'
import { Template } from 'client-shared/components/Template'
import { required } from 'client-shared/utils/validation'
import { CheckboxWithLabel } from 'client-shared/components'

const DuplicateReportModal = ({ handleClose, handleSubmit, invalid, title, openSearchModal }) => (
  <Dialog open maxWidth="sm" fullWidth data-qa="duplicateReport-modal">
    <DialogTitle>Duplicate Report</DialogTitle>
    <DialogContent>
      <Template is="auto-flow 88px / auto">
        <Text label="Job ID" name="jobId" validate={required} required={true} />
        <Text
          label="Address"
          name="fullAddress"
          required={true}
          disabled
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button onClick={openSearchModal}>Change</Button>
              </InputAdornment>
            ),
          }}
        />
      </Template>
      <Typography variant="body1">Copy the following in duplicated report</Typography>
      <CheckboxWithLabel name="options.copyNeighborhood" label="Neighborhood" />
      <CheckboxWithLabel name="options.copyZoning" label="Zoning" />
      <CheckboxWithLabel name="options.copyPropertyPhotos" label="Property Photos" />
      <CheckboxWithLabel name="options.copyTaxes" label="Taxes" />
      <Typography dangerouslySetInnerHTML={{ __html: title }} variant="body2" />
    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={handleClose}>
        Cancel
      </Button>
      <Button color="primary" variant="contained" onClick={handleSubmit} disabled={invalid}>
        Duplicate
      </Button>
    </DialogActions>
  </Dialog>
)

DuplicateReportModal.propTypes = {
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  title: PropTypes.string,
  openSearchModal: PropTypes.func,
}

DuplicateReportModal.defaultProps = {
  // eslint-disable-next-line max-len
  title: `<b>Note:</b> In changing the address, the duplicate will no longer populate: Maps, Flood Zone (if NYC of NJ) and Census Data`,
}

DuplicateReportModal.displayName = 'DuplicateReportModal'

export default DuplicateReportModal
