import { get, isEqual, isEqualWith, isPlainObject, omit, pick, isFinite } from 'lodash'

import { getStateAbbreviation } from 'shared/helpers/property'
import { getExpenseValueByIdFromBoweryCoreProperty } from 'shared/helpers/incomeApproach/expenses/mappers'

import { EXPENSE_PERIOD_VALUES, MONTH_OPTIONS } from '../../../../shared/utils/expenses/constants'
import { arrayToKeyValuePairs } from '../../../../shared/utils/ui/checkboxHelper'

import {
  EXPENSE_COMPARABLES_CATEGORIES,
  EXPENSE_COMPARABLES_INFO,
  GROSS_REVENUE,
  PROPERTY_TYPES as PROPERTY_TYPES_LIST,
} from '../../../../../../shared/constants/expenses/expenseComparables'
import { BASIS_OF_COMPARISON_OPTIONS, NOT_COMPUTABLE_VALUE } from '../../../../../../shared/constants/expenses'
import { STATE_OPTIONS } from '../../../../../../shared/constants/states'

export const getCellPath = (row, itemIndex) => {
  return `comparableExpenses[${itemIndex}].expenses[${row.rowDef.index}].value`
}

export const getExpenseValueByIdFromProperty = (expenseId, property) => {
  switch (expenseId) {
    case EXPENSE_COMPARABLES_CATEGORIES.address:
      return property.address
    case EXPENSE_COMPARABLES_CATEGORIES.city:
      return get(property, 'city') || get(property, 'Borough')
    case EXPENSE_COMPARABLES_CATEGORIES.state: {
      const state = get(property, 'state')
      return getStateAbbreviation(state) || state
    }
    case EXPENSE_COMPARABLES_CATEGORIES.sourceOfInformation:
      return (
        get(property, 'sourceUrl', '') || get(property, 'sourceName', '') || get(property, 'sourceOfInformation', '')
      )
    case EXPENSE_COMPARABLES_CATEGORIES.yearBuilt:
      return get(property, 'YearBuilt') || get(property, 'yearBuilt')
    case EXPENSE_COMPARABLES_CATEGORIES.squareFeet:
      return get(property, 'BldgArea') || get(property, 'squareFeet')
    case EXPENSE_COMPARABLES_CATEGORIES.residentialUnits:
      return get(property, 'UnitsRes') || 0
    default:
      return null
  }
}

export const getType = id => {
  switch (id) {
    case EXPENSE_COMPARABLES_CATEGORIES.city:
      return {
        rowType: 'text',
      }
    case EXPENSE_COMPARABLES_CATEGORIES.address:
      return 'text'
    case EXPENSE_COMPARABLES_CATEGORIES.state:
      return {
        rowType: 'autosuggest',
        inputProps: {
          options: STATE_OPTIONS.map(item => item.abbr),
        },
      }
    case EXPENSE_COMPARABLES_CATEGORIES.sourceOfInformation:
      return 'link'
    case EXPENSE_COMPARABLES_CATEGORIES.expenseYear:
      return {
        rowType: 'year',
      }
    case EXPENSE_COMPARABLES_CATEGORIES.yearBuilt:
      return 'year'
    case EXPENSE_COMPARABLES_CATEGORIES.expensePeriod:
      return {
        rowType: 'select',
        inputProps: {
          options: EXPENSE_PERIOD_VALUES,
        },
      }
    case EXPENSE_COMPARABLES_CATEGORIES.expenseMonth:
      return { rowType: 'select', inputProps: { options: MONTH_OPTIONS } }
    case EXPENSE_COMPARABLES_CATEGORIES.squareFeet:
    case EXPENSE_COMPARABLES_CATEGORIES.residentialUnits:
      return 'positiveInteger'
    case EXPENSE_COMPARABLES_CATEGORIES.propertyType:
      return {
        rowType: 'select',
        inputProps: {
          options: arrayToKeyValuePairs(PROPERTY_TYPES_LIST),
        },
      }
    default:
      return 'money'
  }
}

export const getValuePerBasis = (basis, value, colDef) => {
  let divider = null
  switch (basis) {
    case BASIS_OF_COMPARISON_OPTIONS.PER_SF:
      divider = colDef?.sf
      break
    case BASIS_OF_COMPARISON_OPTIONS.PER_UNIT:
      divider = colDef?.resUnits
      break
    case BASIS_OF_COMPARISON_OPTIONS.GROSS:
      divider = 1
      break
    default:
      return null
  }
  if (divider && isFinite(value)) {
    return value / divider
  }
  return isFinite(value) ? NOT_COMPUTABLE_VALUE : null
}

export function mapCompPlexExpenseComps(expenseComps, showRETaxes) {
  const mappedExpenseComps = expenseComps.map(expenseComp => {
    const _id = get(expenseComp, '_id', null)
    const result = pick(expenseComp, [
      'state',
      'location',
      'expenseHistoryReportId',
      'isApproved',
      'updatedAt',
      'createdAt',
      'manuallyCreated',
      'version',
    ])
    const infoExpenses = Object.keys(EXPENSE_COMPARABLES_INFO).map(id => ({
      id,
      value: getExpenseValueByIdFromBoweryCoreProperty(id, expenseComp),
    }))
    const expenses = Object.entries(expenseComp.expenses).map(([id, category]) => {
      return {
        ...category,
        id: id,
        value: get(expenseComp, `expenses.${id}.total`, get(expenseComp, `expenses.${category.name}.total`)),
      }
    })

    return {
      _id,
      boweryId: expenseComp.boweryId,
      key: _id,
      ...result,
      total: showRETaxes ? expenseComp.expensesTotalGross.total : expenseComp.expensesTotalNet.total,
      totalPerSF: showRETaxes ? expenseComp.expensesTotalGross.psf : expenseComp.expensesTotalNet.psf,
      totalPerUnit: showRETaxes ? expenseComp.expensesTotalGross.perUnit : expenseComp.expensesTotalNet.perUnit,
      expenses: [...infoExpenses, ...expenses],
      hiddenExpenses: [],
    }
  })

  return mappedExpenseComps
}

// On some reports, expense categories have the wrong average. When decorators calculate the correct value on load,
// we don't want the field to be dirty, otherwise the save modal will be triggered.
export const areExpenseCategoriesEqual = (categoryA, categoryB) => {
  return isEqualWith(categoryA, categoryB, (value, otherValue) => {
    if (isPlainObject(value) && isPlainObject(otherValue)) {
      return isEqual(omit(value, 'average'), omit(otherValue, 'average'))
    }
  })
}

export function calculateExpenseTotals(calculate, expenses) {
  const { resUnits, squareFeet } = getResUnitsAndSqft({ expenses })

  const { total, totalPerSf, totalPerUnit } = calculate(
    expenses?.filter(expense => expense.id !== GROSS_REVENUE && !EXPENSE_COMPARABLES_INFO[expense.id]),
    squareFeet,
    resUnits
  )
  return {
    total: total || null,
    totalPerSF: totalPerSf || null,
    totalPerUnit: totalPerUnit || null,
  }
}

export const getResUnitsAndSqft = ({ record = {}, expenses = [] }) => {
  const sf = expenses.find(row => row.id === EXPENSE_COMPARABLES_CATEGORIES.squareFeet)
  const residentialUnits = expenses.find(row => row.id === EXPENSE_COMPARABLES_CATEGORIES.residentialUnits)

  return {
    resUnits: residentialUnits?.value || 0,
    squareFeet: sf?.value || 0,
  }
}
