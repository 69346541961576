import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Form } from 'react-final-form'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Paper,
  DialogContentText,
} from '@material-ui/core'

import { SORT_DIRECTIONS } from '../../../../constants'

import { expenseCompsFetch, expenseCompsClear, selectExpenseComp, deselectExpenseComp } from './redux/actions'

import { DIALOG_FILTER_TITLE, DIALOG_UNITS_TITLE } from './constants'

import ExpenseCompsFiltersTable from './ExpenseCompsFiltersTable'
import ResultsTable from './ResultsTableContainer'

const styles = {
  paper: {
    marginTop: 10,
  },
}

const formInitialValues = {
  tableFilters: {
    sortField: 'address',
    sortDirection: SORT_DIRECTIONS.DESC,
    pageSize: 10,
    currentPage: 0,
    locations: [],
    state: '',
    expensePeriod: '',
    type: '',
  },
  options: {
    expenseBasis: 'sf',
    includeRETaxes: true,
  },
}

const formMutators = {
  setPageSize: ([pageSize], state, utils) => {
    utils.changeValue(state, 'tableFilters.pageSize', () => pageSize)
    utils.changeValue(state, 'tableFilters.currentPage', () => 0)
  },
  setSortField: ([sortField, sortDirection], state, utils) => {
    utils.changeValue(state, 'tableFilters.sortField', () => sortField)
    utils.changeValue(state, 'tableFilters.sortDirection', () => sortDirection)
  },
  setCurrentPage: ([currentPage], state, utils) => {
    utils.changeValue(state, 'tableFilters.currentPage', () => currentPage)
  },
  setLocation: ([locations], state, utils) => {
    utils.changeValue(state, 'tableFilters.locations', () => locations)
    utils.changeValue(state, 'tableFilters.currentPage', () => 0)
  },
  setLocationState: ([locationState], state, utils) => {
    utils.changeValue(state, 'tableFilters.state', () => locationState)
    utils.changeValue(state, 'tableFilters.currentPage', () => 0)
  },
  setExpensePeriod: ([expensePeriod], state, utils) => {
    utils.changeValue(state, 'tableFilters.expensePeriod', () => expensePeriod)
    utils.changeValue(state, 'tableFilters.currentPage', () => 0)
  },
  setPropertyType: ([type], state, utils) => {
    utils.changeValue(state, 'tableFilters.type', () => type)
    utils.changeValue(state, 'tableFilters.currentPage', () => 0)
  },
}

class ComparableExpensesUnitsModal extends React.PureComponent {
  static propTypes = {
    onAddExpenseComps: PropTypes.func.isRequired,
    selectedExpenseComps: PropTypes.array,
    handleClose: PropTypes.func.isRequired,
    filters: PropTypes.array,
    propertySummary: PropTypes.object.isRequired,
    totalOperatingExpenses: PropTypes.object.isRequired,
    subjectExpenses: PropTypes.object.isRequired,
  }

  static defaultProps = {
    filters: [],
    selectedExpenseComps: [],
  }

  state = {
    isInFiltersState: true,
    isInUnitsState: false,
    selectedExpenseComps: [],
  }

  onSearchExpenseCompsClick = () => {
    this.setState({
      isInFiltersState: false,
      isInUnitsState: true,
    })
  }

  onBackToFiltersClick = () => {
    this.setState({
      isInFiltersState: true,
      isInUnitsState: false,
    })
  }

  onAddExistingComp = () => {
    const mappedExpenseComps = this.props.selectedExpenseComps.map(comp => {
      return {
        ...comp,
        id: comp._id,
      }
    })

    this.props.onAddExpenseComps(mappedExpenseComps)
  }

  onCheckChange = (expenseComp, isChecked) => {
    const { selectExpenseComp, deselectExpenseComp } = this.props
    const { _id: id } = expenseComp

    if (isChecked) {
      selectExpenseComp(expenseComp)
    } else {
      deselectExpenseComp(id)
    }
  }

  onSelectedComps = comps => {
    this.setState({ selectedExpenseComps: comps })
  }

  render() {
    const {
      classes,
      handleClose,
      propertySummary,
      subjectExpenses,
      totalOperatingExpenses,
      addedExpenseComps,
      filters,
    } = this.props

    const expenseCompsDisabled = !global.env.enableKnowledgeworld || !global.env.enableExpenseComps

    const { isInFiltersState, isInUnitsState } = this.state
    return (
      <Dialog
        open
        disableBackdropClick
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        data-qa="expense-search-modal"
      >
        <DialogTitle id="simple-dialog-title">
          {isInFiltersState && DIALOG_FILTER_TITLE}
          {isInUnitsState && DIALOG_UNITS_TITLE}
        </DialogTitle>
        <Form
          keepDirtyOnReinitialize
          onSubmit={this.onAddExistingComp}
          initialValues={formInitialValues}
          mutators={formMutators}
          render={({ values, form, handleSubmit }) => {
            if (expenseCompsDisabled) {
              return (
                <React.Fragment>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Search has been disabled temporarily. Please enable Knowledge World to use search.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="default" data-qa="close-modal-btn">
                      Close
                    </Button>
                  </DialogActions>
                </React.Fragment>
              )
            } else {
              return (
                <React.Fragment>
                  <DialogContent>
                    <FormControl className={classes.formControl}>
                      <Paper className={classes.paper}>
                        {isInFiltersState && (
                          <ExpenseCompsFiltersTable
                            filters={filters}
                            propertySummary={propertySummary}
                            totalOperatingExpenses={totalOperatingExpenses}
                            subjectExpenses={subjectExpenses}
                            expenseBasis={values.options.expenseBasis}
                          />
                        )}
                        {isInUnitsState && (
                          <ResultsTable
                            onSelectExpenseComp={this.onSelectExpenseComp}
                            onDeselectExpenseComp={this.onDeselectExpenseComp}
                            onSelectedComps={this.onSelectedComps}
                            onCheckChange={this.onCheckChange}
                            addedExpenseComps={addedExpenseComps}
                            form={form}
                            {...values}
                            {...form.mutators}
                          />
                        )}
                      </Paper>
                    </FormControl>
                  </DialogContent>
                  <DialogActions>
                    {isInFiltersState && (
                      <Button onClick={this.onSearchExpenseCompsClick} color="primary" data-qa="find-comps-btn">
                        Find Comparables
                      </Button>
                    )}
                    {isInUnitsState && (
                      <React.Fragment>
                        <Button onClick={this.onBackToFiltersClick} color="primary" data-qa="back-to-filters-btn">
                          Back To Filters
                        </Button>
                        <Button onClick={handleSubmit} color="primary" data-qa="add-comps-btn">
                          Add Comps
                        </Button>
                      </React.Fragment>
                    )}
                    <Button onClick={handleClose} color="default" data-qa="close-modal-btn">
                      Close
                    </Button>
                  </DialogActions>
                </React.Fragment>
              )
            }
          }}
        />
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const expenseComps = get(state, 'expenseComps', {})
  return {
    ...expenseComps,
    ...ownProps,
  }
}

const mapDispatchToProps = (dispatch, { filters, tableFilters }) => ({
  fetchExpenseComps: comparableExpenses => dispatch(expenseCompsFetch({ filters, tableFilters, comparableExpenses })),
  clearExpenseComps: () => dispatch(expenseCompsClear()),
  selectExpenseComp: expenseComp => dispatch(selectExpenseComp({ expenseComp })),
  deselectExpenseComp: id => dispatch(deselectExpenseComp({ id })),
})

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ComparableExpensesUnitsModal)
