import HttpClientManager from '../xhr/HttpClientManager'

import { getAuthorizationHeader } from '../core/api'

const httpClient = HttpClientManager.createHttpClient({
  baseURL: `${global.env.apiUrl}`,
})

const httpClientMarketAnalysis = HttpClientManager.createHttpClient({
  baseURL: `${global.env.marketResearchApiUrl}`,
})

export async function updateReport(reportId, formDataPath, values) {
  const response = await httpClient.patch(
    `/report/${reportId}`,
    {
      formDataPath,
      values,
      // could not get this variable to thread through from saveReport call to here (it gets lost in redux somewhere)
      automationSaved: global?.automationSaved || null,
    },
    {
      headers: getAuthorizationHeader(),
    }
  )

  // clear automationSaved flag
  global.automationSaved = null

  return {
    reportData: response.data.new,
    reportSettings: response.data.settings,
    old: response.data.old,
    reportStructure: response.data.structure,
  }
}

export async function updateReportSettings(reportId, settings) {
  const response = await httpClient.patch(`/report/settings/${reportId}`, settings, {
    headers: getAuthorizationHeader(),
  })

  return {
    reportData: response.data.new,
    reportSettings: response.data.settings,
    reportStructure: response.data.structure,
  }
}

export async function updateReportState(reportId, prevState, newState) {
  const response = await httpClient.patch(
    `/report/state/${reportId}`,
    {
      prevState,
      newState,
    },
    {
      headers: getAuthorizationHeader(),
    }
  )

  return {
    ...response.data.review,
  }
}

export async function fetchReport(reportId) {
  const response = await httpClient.get(`/report/${reportId}`, {
    headers: getAuthorizationHeader(),
  })

  return {
    reportData: response.data.new,
    old: response.data.old,
    reportSettings: response.data.settings,
    reportStructure: response.data.structure,
  }
}

export async function fetchRentComps(reportId) {
  const response = await httpClient.get(`/residentialRentComps/report/${reportId}`, {
    headers: getAuthorizationHeader(),
  })

  return response.data.comps
}

export async function generateReport(reportId, data) {
  const response = await httpClient.post(`/api/docx-report-async/${reportId}`, data, {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function generateReportFromJSON(reportId, data) {
  const response = await httpClient.post(`/api/docx-report-async/from-json/${reportId}`, data, {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function fetchExports(reportId) {
  const response = await httpClient.get(`/api/docx-report-async/get-reports-generation/${reportId}`, {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function runAutomation(category, reportId) {
  const response = await httpClient.post(
    `/api/automation/import/${category}/${reportId}`,
    {},
    {
      headers: getAuthorizationHeader(),
    }
  )

  return response.data
}

export async function runPropertyDataAutomation({ reportId, address, city, state, zip }) {
  const response = await httpClient.post(
    `/api/automation/prefill-property-data/${reportId}`,
    { address, city, state, zip },
    {
      headers: getAuthorizationHeader(),
    }
  )

  return response.data
}

export async function runExpenseForecastAutomation(reportId) {
  const response = await httpClient.post(
    `/api/automation/import/expense-forecast/${reportId}`,
    {},
    {
      headers: getAuthorizationHeader(),
    }
  )

  return response.data
}

export async function getReportHierarchy(baseTemplateFileName) {
  const response = await httpClient.get(`/api/docx-report-async/get-report-hierarchy`, {
    headers: getAuthorizationHeader(),
    params: {
      baseTemplateFileName,
    },
  })

  return response.data
}

export async function getSalesComps(filters) {
  const response = await httpClient.post('/salesComps/search-comps', filters, {
    headers: getAuthorizationHeader(),
  })
  return response.data
}

export async function getTaxComps(data) {
  const response = await httpClient.get('/tax-info/taxComps', {
    headers: getAuthorizationHeader(),
    params: data,
  })
  return response.data
}

export async function fetchExpenseComps(reportId, filters, sortField, sortDirection, pageSize, currentPage) {
  const response = await httpClient.post(
    '/expenseComps/search-comps',
    {
      ...filters,
      reportId,
      sortField,
      sortDirection,
      pageSize,
      currentPage,
    },
    { headers: getAuthorizationHeader() }
  )
  const expenseComps = response.data.comps.map(expenseComp => {
    return { ...expenseComp }
  })

  const totalCount = response.data.totalCount
  return {
    totalCount,
    expenseComps,
    availableLocations: response.data.availableLocations,
    availableStates: response.data.availableStates,
  }
}

export async function fetchExpenseCompsFromCompPlex(
  reportId,
  filters,
  sortField,
  sortDirection,
  pageSize,
  currentPage
) {
  const response = await httpClient.post(
    '/api/compplex/expense-comps',
    {
      ...filters,
      reportId,
      sortField,
      sortDirection,
      pageSize,
      currentPage,
    },
    { headers: getAuthorizationHeader() }
  )
  const expenseComps = response.data.comps.map(expenseComp => {
    return { ...expenseComp }
  })

  const totalCount = response.data.totalCount
  return {
    totalCount,
    expenseComps,
    availableLocations: response.data.availableLocations,
    availableStates: response.data.availableStates,
  }
}

export async function fetchExpenseCompsVersionsFromCompPlex(ids) {
  const response = await httpClient.post(
    '/api/compplex/expense-comps-versions',
    { ids },
    { headers: getAuthorizationHeader() }
  )

  return response.data
}

export async function fetchExpenseCompsByReportId(reportId) {
  const response = await httpClient.get(`/report/${reportId}/comparableExpenses`, {
    headers: getAuthorizationHeader(),
  })
  return response.data
}

export async function fetchZoningByReportId(reportId) {
  const response = await httpClient.get(`/zoning/report/${reportId}`, {
    headers: getAuthorizationHeader(),
  })
  return response.data
}

export async function getZoningData(address) {
  const response = await httpClient.get(`/zoning/thirdpartydata/${address}`, {
    headers: getAuthorizationHeader(),
  })
  return response.data
}

export async function getAutoSalesComps(subject, isLandSearch = false, settings = null) {
  const response = await httpClient.post(
    `/api/compplex/autoSalesComps`,
    { ...subject, settings, isLandSearch },
    {
      headers: getAuthorizationHeader(),
    }
  )
  return response.data
}

export async function getAutoResiRentComps(subject, settings = null) {
  const response = await httpClient.post(
    `/api/compplex/autoResiRentComps`,
    { ...subject, settings },
    {
      headers: getAuthorizationHeader(),
    }
  )
  return response.data
}

export async function getAutoExpenseComps(reportId, subject, settings = null) {
  const response = await httpClient.post(
    `/api/automation/automate/expense-comps/${reportId}`,
    { settings, subject },
    {
      headers: getAuthorizationHeader(),
    }
  )
  return response.data
}

export async function fetchReportSettingsByReportNumber(reportNumber) {
  const response = await httpClient.get(`/salesforce/report-settings/${reportNumber}`, {
    headers: getAuthorizationHeader(),
  })
  return response.data
}

export async function fetchCapRateCompsByReportId(reportId) {
  const response = await httpClient.get(`/report/${reportId}/capRateComparables`, {
    headers: getAuthorizationHeader(),
  })
  return response.data
}

export async function fetchTaxCompsByReportId(reportId, basis) {
  const response = await httpClient.get(`/report/${reportId}/taxComparables`, {
    headers: getAuthorizationHeader(),
    params: { basis },
  })
  return response.data
}

export async function fetchSalesTransactionIdsByReportId(reportId) {
  const response = await httpClient.get(`/salesComps/transactionIds/${reportId}`, {
    headers: getAuthorizationHeader(),
  })
  return response.data
}

export async function fetchCommercialRentCompsByIdAndVersion(reportId) {
  const report = await fetchReport(reportId)
  const { commercialRentComps } = report.old
  return commercialRentComps
}

export async function takeSnapshot(reportId, subject, dataPaths) {
  const response = await httpClient.post(
    `/api/report/snapshot`,
    {
      reportId,
      subject,
      dataPaths,
    },
    {
      headers: getAuthorizationHeader(),
    }
  )
  return response.data
}

export async function getTransitInfo(data) {
  const { latitude, longitude } = data
  const response = await httpClient.get(`/api/transit`, {
    headers: getAuthorizationHeader(),
    params: {
      latitude,
      longitude,
    },
  })

  return response.data
}

export async function addPageBookmark(data) {
  const response = await httpClient.post('/bookmarks', data, {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function removePageBookmark(data) {
  const response = await httpClient.delete(`/bookmarks/`, {
    data,
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function removeAllBookmarks(data) {
  const response = await httpClient.delete(`/bookmarks/all`, {
    data,
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function fetchBookmarks(reportId) {
  const response = await httpClient.get(`/bookmarks/`, {
    headers: getAuthorizationHeader(),
    params: { reportId },
  })

  return response.data
}

export async function updateNotes(reportId, note) {
  const response = await httpClient.post(
    `/notes/${reportId}`,
    {
      note,
    },
    {
      headers: getAuthorizationHeader(),
    }
  )

  return response.data
}

export async function xmlGeneration(reportId) {
  const response = await httpClient.get(`/api/xmlGeneration/${reportId}`)
  return response.data
}

export async function getMarketInformation({ marketYear, marketQuarter, neighborhoodYear, marketState }) {
  const response = await httpClientMarketAnalysis.get('/list', {
    params: {
      state: marketState,
      quarter: marketQuarter,
      neighborhood_year: neighborhoodYear,
      market_year: marketYear,
    },
  })
  return response.data
}

export async function getAllMarketReports({
  marketNeighborhood,
  marketYear,
  neighborhoodYear,
  marketQuarter,
  marketState,
  marketArea,
  markets,
}) {
  const payload = {
    neighborhood_name: marketNeighborhood,
    market_year: marketYear.toString(),
    neighborhood_year: neighborhoodYear.toString(),
    quarter: marketQuarter,
    state: marketState,
    area: marketArea,
    markets,
  }
  const response = await httpClientMarketAnalysis.post('/reports', payload)
  return response.data || []
}

export async function getMarketReport(reportId) {
  const response = await httpClientMarketAnalysis.get(`/reports/${reportId}`, {
    responseType: 'arraybuffer',
  })
  const metadata = {
    type: response.headers['content-type'],
  }
  const fileName = response.headers['bowery-file-name']
  const fileBlob = new Blob([response.data])

  return new File([fileBlob], fileName, metadata)
}
