import React from 'react'
import PropTypes from 'prop-types'

import { Form } from 'react-final-form'
import { get } from 'lodash'

import { connect } from 'react-redux'

import { Loading, PropertyWizard } from '../../../shared/components'
import { duplicateReport as duplicateReportAction } from '../../redux/actions'
import { getDisplayAddress } from '../../../shared/utils/propertyHelper'

import DuplicateReportModal from './DuplicateReportModal'

class DuplicateReportModalContainer extends React.Component {
  state = {
    isCreatingReport: false,
    propertySearchOpen: false,
    property: null,
  }

  onSubmit = values => {
    const { property } = this.state
    const { initialValues, duplicateReport } = this.props
    const { jobId, options } = values
    this.setState({ isCreatingReport: true })
    duplicateReport({ reportId: get(initialValues, 'id'), jobId, propertyInfo: property, options })
    this.setState({ property: null })
  }

  openSearchModal = () => {
    this.setState({
      propertySearchOpen: true,
    })
  }

  closeSearchModal = () => {
    this.setState({
      propertySearchOpen: false,
    })
  }

  handleCloseLocal = () => {
    if (this.state.propertySearchOpen) {
      this.closeSearchModal()
    }
    this.props.handleClose()
  }

  changeAddress = (form, property) => {
    const { address, city, state, zip } = property
    const fullAddress = getDisplayAddress({ address, city, state, zip })
    form.change('fullAddress', fullAddress)
    this.setState({
      propertySearchOpen: false,
      property,
    })
  }

  render() {
    const { open, handleClose, initialValues } = this.props
    const { isCreatingReport, propertySearchOpen } = this.state
    const { openSearchModal } = this
    if (!open) {
      return null
    }
    return (
      <>
        {isCreatingReport && <Loading />}
        <Form
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          render={({ handleSubmit, invalid, form }) => (
            <>
              {!propertySearchOpen && (
                <DuplicateReportModal {...{ handleClose, handleSubmit, invalid, openSearchModal }} />
              )}
              <PropertyWizard
                handleBack={this.closeSearchModal}
                handleClose={this.handleCloseLocal}
                onSubmit={property => this.changeAddress(form, property)}
                open={propertySearchOpen}
                title="Find Subject Property"
              />
            </>
          )}
        />
      </>
    )
  }
}

DuplicateReportModalContainer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
}

export default connect(null, {
  duplicateReport: duplicateReportAction,
})(DuplicateReportModalContainer)
