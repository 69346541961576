import React from 'react'
import PropTypes from 'prop-types'

import { get, isEqual, capitalize } from 'lodash'

import { Grid, PagingPanel, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui'
import { CustomPaging, FilteringState, PagingState, SortingState } from '@devexpress/dx-react-grid'
import { TableRow, Checkbox } from '@mui/material'
import Dash from '@mui/icons-material/Remove'

import Loading from 'client-shared/components/Loading'
import MultipleDropDown from 'client-shared/components/DropDown/MultipleDropDownField'
import NoDataCell from 'client-shared/components/ReportsTable/NoDataCell'
import PropertyTypeIcon from 'client-shared/components/PropertyTypeIcon'
import { BOROUGH_LOOKUP } from 'shared/constants/boroughs'
import { EXPENSE_PERIOD_VALUES, BUILDING_TYPES } from 'client-shared/utils/expenses/constants'
import { STATE_NAMES } from 'shared/constants/states'
import { SelectField as Select } from 'client-shared/components/Select/Select'
import { errorNotification } from 'client-shared/redux/actions/notifications'
import { formatInt, formatCurrencyFloat } from 'client-shared/utils/numberFormatters'
import { getSourceOfInformation } from 'client-shared/components/SourceOfInformation'

import AutosuggestFilterInput from './AutosuggestFilterInput'
import {
  ERROR_MESSAGE,
  EXPENSE_COMPS_TABLE_COLUMNS,
  EXPENSE_COMPS_TABLE_EXTENSIONS,
  RESULTS_TABLE_COLUMN_HEADERS,
} from './constants'
import { fetchExpenseComps } from './BoweryCoreFacade'

class ResultsTable extends React.Component {
  //TODO: move to final form
  state = {
    expenseComps: [],
    totalCount: null,
    locationState: '',
  }

  async componentDidMount() {
    const { addedExpenseComps, selectedExpenseComps, tableFilters, filters, options, errorNotification, reportId } =
      this.props
    try {
      const checkedComps = [...addedExpenseComps, ...selectedExpenseComps]
      const { expenseComps, totalCount, availableLocations, availableStates } = await fetchExpenseComps({
        checkedComps,
        options,
        filters,
        reportId,
        tableFilters,
      })
      this.setState({
        availableLocations,
        availableStates,
        expenseComps,
        totalCount,
      })
    } catch (err) {
      errorNotification(ERROR_MESSAGE)
    }
  }

  componentWillUnmount() {
    const { clearExpenseComps } = this.props

    clearExpenseComps()
  }

  async componentDidUpdate(prevProps) {
    const { addedExpenseComps, selectedExpenseComps, tableFilters, filters, options, reportId } = this.props

    try {
      if (!isEqual(prevProps.tableFilters, this.props.tableFilters)) {
        const checkedComps = [...addedExpenseComps, ...selectedExpenseComps]
        const { expenseComps, totalCount, availableLocations, availableStates } = await fetchExpenseComps({
          checkedComps,
          options,
          filters,
          reportId,
          tableFilters,
        })
        this.setState({
          availableLocations,
          availableStates,
          expenseComps,
          totalCount,
        })
      }
    } catch (err) {
      errorNotification(ERROR_MESSAGE)
    }
  }

  get locationsSelect() {
    const { setLocation } = this.props
    const { availableLocations } = this.state

    if (availableLocations) {
      const locations = availableLocations.map(location => ({
        label: BOROUGH_LOOKUP[location] || location,
        value: location,
      }))
      return (
        <MultipleDropDown
          handleChange={setLocation}
          items={locations}
          maxChips={1}
          name="tableFilters.locations"
          noResultsText="No comps found from this city."
          placeholder="City"
        />
      )
    } else {
      return 'Location'
    }
  }

  handleLocationStateChange = event => {
    const { setLocationState } = this.props
    const propName = event.target.name
    const propValue = event.target.value.split(' ').map(capitalize).join(' ')
    this.setState({ [propName]: propValue })
    setLocationState(propValue)
  }

  get statesSelect() {
    const { availableStates } = this.state

    if (availableStates) {
      const states = availableStates.map(state => STATE_NAMES[state] || state)
      return (
        <AutosuggestFilterInput
          data-qa="expense-period-state"
          name="locationState"
          onChange={this.handleLocationStateChange}
          options={states}
          value={this.state.locationState}
        />
      )
    } else {
      return 'State'
    }
  }

  get expensePeriodSelect() {
    const { onPeriodChange } = this.props

    return (
      <Select
        data-qa="expense-period-select"
        items={EXPENSE_PERIOD_VALUES}
        name="tableFilters.expensePeriod"
        onChange={onPeriodChange}
      />
    )
  }

  get buildingTypeSelect() {
    const { onPeriodChange } = this.props

    return (
      <Select
        name="tableFilters.type"
        items={BUILDING_TYPES}
        onChange={onPeriodChange}
        data-qa="building-type-select"
      />
    )
  }

  selectComp = comp => {
    this.props.onCheckChange(comp, !comp.isSelected)
    comp.isSelected = !comp.isSelected
  }

  tableRowTemplate = props => {
    return (
      <TableRow
        className="table-row"
        data-qa={`row-${props.tableRow.rowId}`}
        onClick={() => this.selectComp(props.row)}
      >
        {props.children}
      </TableRow>
    )
  }

  tableCellTemplate = props => {
    const { options } = this.props
    if (props.column.name === RESULTS_TABLE_COLUMN_HEADERS.ID) {
      return (
        <Table.Cell data-qa="id-cell">
          <Checkbox name={props.row._id} checked={props.row.isSelected} />
        </Table.Cell>
      )
    }

    if (props.column.name === RESULTS_TABLE_COLUMN_HEADERS.OPERATING_EXPENSES_SF) {
      const dataPath = options.includeRETaxes ? 'expensesTotalGross' : 'expensesTotalNet'
      const unit = get(props, `row.${dataPath}.psf`)
      return (
        <Table.Cell {...props} data-qa={`${props.column.name}-cell`}>
          {formatCurrencyFloat(unit)}
        </Table.Cell>
      )
    }

    if (props.column.name === RESULTS_TABLE_COLUMN_HEADERS.OPERATING_EXPENSES_UNIT) {
      const dataPath = options.includeRETaxes ? 'expensesTotalGross' : 'expensesTotalNet'
      const unit = get(props, `row.${dataPath}.perUnit`)
      return (
        <Table.Cell {...props} data-qa={`${props.column.name}-cell`}>
          {formatCurrencyFloat(unit)}
        </Table.Cell>
      )
    }

    if (props.column.name === RESULTS_TABLE_COLUMN_HEADERS.SQFT) {
      const squareFeet = get(props, 'row.squareFeet')
      return (
        <Table.Cell {...props} data-qa="squareFeet-cell">
          {formatInt(squareFeet)}
        </Table.Cell>
      )
    }

    if (props.column.name === RESULTS_TABLE_COLUMN_HEADERS.TYPE) {
      const buildingType = get(props, 'row.buildingType')

      return (
        <Table.Cell {...props} data-qa={`${props.column.name}-cell`}>
          <PropertyTypeIcon propertyType={buildingType} />
        </Table.Cell>
      )
    }

    if (props.column.name === RESULTS_TABLE_COLUMN_HEADERS.EXPENSE_YEAR) {
      let expenseYear = get(props, `row.${props.column.name}`, '')
      expenseYear = expenseYear ? expenseYear : <Dash />
      return (
        <Table.Cell {...props} data-qa={`${props.column.name}-cell`}>
          {expenseYear}
        </Table.Cell>
      )
    }

    if (props.column.name === RESULTS_TABLE_COLUMN_HEADERS.SOURCE_OF_INFORMATION) {
      const sourceOfInformation = getSourceOfInformation(props.row)
      return (
        <Table.Cell {...props} data-qa={`${props.column.name}-cell`}>
          {sourceOfInformation}
        </Table.Cell>
      )
    }

    return (
      <Table.Cell {...props} data-qa={`${props.column.name}-cell`}>
        {get(props, `row.${props.column.name}`) || <Dash />}
      </Table.Cell>
    )
  }

  tableNoDataCellTemplate = ({ colSpan }) => (
    <NoDataCell loading={this.props.isLoading} label="No Data" colSpan={colSpan} data-qa="no-data-cell" />
  )

  TableHeaderContentBase = ({ column, children, ...restProps }) => {
    if (column.name === 'state') {
      return this.statesSelect
    }

    if (column.name === 'location') {
      return this.locationsSelect
    }

    return (
      <TableHeaderRow.Content column={column} {...restProps}>
        {children}
      </TableHeaderRow.Content>
    )
  }

  onSortFieldChange = ([{ columnName, direction }]) => {
    this.props.setSortField(columnName, direction)
  }

  render() {
    const { isLoading, locations, tableFilters, allowedPageSizes } = this.props
    const { sortField, sortDirection } = tableFilters

    const { expenseComps, totalCount } = this.state

    return (
      <div data-qa="expense-comps-table">
        {isLoading && <Loading />}
        <Grid rows={[...expenseComps]} columns={EXPENSE_COMPS_TABLE_COLUMNS}>
          <FilteringState filters={[{ columnName: 'locations', value: locations }]} />
          <SortingState
            sorting={[{ columnName: sortField, direction: sortDirection }]}
            onSortingChange={this.onSortFieldChange}
          />
          <PagingState
            currentPage={this.props.tableFilters.currentPage}
            pageSize={this.props.tableFilters.pageSize}
            onCurrentPageChange={this.props.setCurrentPage}
            onPageSizeChange={this.props.setPageSize}
          />
          <CustomPaging totalCount={totalCount} />
          <Table
            rowComponent={this.tableRowTemplate}
            cellComponent={this.tableCellTemplate}
            noDataCellComponent={this.tableNoDataCellTemplate}
            columnExtensions={EXPENSE_COMPS_TABLE_EXTENSIONS}
          />
          <TableHeaderRow showSortingControls contentComponent={this.TableHeaderContentBase} />
          <PagingPanel pageSizes={allowedPageSizes} />
        </Grid>
      </div>
    )
  }
}

ResultsTable.propTypes = {
  addedExpenseComps: PropTypes.arrayOf(PropTypes.object).isRequired,
  clearExpenseComps: PropTypes.func.isRequired,
  expenseComps: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchExpenseComps: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  locationIdentifier: PropTypes.string.isRequired,
  selectedExpenseComps: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalCount: PropTypes.number.isRequired,
}

export default ResultsTable
