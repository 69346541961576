import React from 'react'
import { findIndex, noop } from 'lodash'
import ObjectID from 'bson-objectid'

import { Grid } from '@material-ui/core'

import { ColumnDataTypeEnum, RowBasedTable, CELL_COLUMN_CONFIGURATIONS } from '@bowery-valuation/ui-components'
import { getCustomColumnConfig } from 'client-shared/utils/rowBasedTable'
import useFieldValue from 'report/utils/useFieldValue'

import Button from 'client-shared/components/_mui5/Button'

import { CheckboxWithLabel } from '../../../../shared/components'

import { OTHER_GEO_DEFAULT_REGULATION, OTHER_GEO_SPECIFIC_REGULATION_NAMES, CONFORMING_STATUSES } from './constants'

export const RegulationsTable = ({ form, isNYCReport }) => {
  const showRegulationsActualColumn = useFieldValue('showRegulationsActualColumn')

  const residentialUnits = useFieldValue('residentialUnits')
  const siteArea = useFieldValue('siteArea')

  const getTableColumns = () => {
    const columns = [
      {
        name: 'name',
        label: 'Regulation',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'left',
        editable: true,
        autoHeight: true,
        textWrap: true,
        resizable: true,
        minWidth: 225,
      },
      {
        name: 'required',
        label: 'Required',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: true,
        autoHeight: true,
        textWrap: true,
        resizable: true,
        minWidth: 250,
      },
      {
        name: 'actual',
        label: 'Actual',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        editable: true,
        align: 'right',
        autoHeight: true,
        textWrap: true,
        resizable: true,
        minWidth: 100,
      },
      {
        name: 'conformingStatus',
        label: 'Status',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        editable: true,
        align: 'right',
        resizable: true,
        minWidth: 125,
        cellEditorSelector: () => {
          return {
            component: 'selectCellEditor',
            params: {
              ...CELL_COLUMN_CONFIGURATIONS.select.params,
              inputProps: {
                options: CONFORMING_STATUSES,
              },
            },
          }
        },
      },
    ]
    if (!showRegulationsActualColumn) {
      return columns.filter(column => column.name !== 'actual')
    }

    return columns
  }

  const addRegulation = () => {
    const regulations = form.getState().values.regulations || []
    const numberOfRegulations = regulations.length || 0
    form.mutators.push('regulations', {
      readOnly: false,
      suppressMovable: false,
      permanent: false,
      autoHeight: true,
      textWrap: true,
      type: ColumnDataTypeEnum.text,
      index: numberOfRegulations,
      id: ObjectID().toString(),
      name: '',
      required: '',
      actual: '',
      conformingStatus: '',
    })
  }

  const getRegulationRows = () => {
    const regulations = form.getState().values.regulations || []
    return regulations.map(({ name, ...other }, index) => {
      return {
        id: name,
        name,
        ...other,
        index,
        readOnly: false,
        suppressMovable: false,
        permanent: false,
        autoHeight: true,
        textWrap: true,
        type: ColumnDataTypeEnum.text,
      }
    })
  }

  const onRowUpdate = row => {
    const regulations = form.getState().values.regulations || []
    const { id, name, required, actual, conformingStatus } = row
    const regulationIndex = findIndex(regulations, (regulation, index) => regulation.id === id)
    const regulationToUpdate = regulations[regulationIndex]
    regulationToUpdate.name = name
    regulationToUpdate.required = required
    regulationToUpdate.actual = actual
    regulationToUpdate.conformingStatus = conformingStatus
    form.mutators.update('regulations', regulationIndex, regulationToUpdate)
  }

  const onRowDelete = rowId => {
    const regulations = form.getState().values.regulations || []
    const foundIndex = findIndex(
      regulations,
      regulation => regulation.id.toString() === rowId || regulation._id === rowId
    )
    if (regulations[foundIndex]) {
      form.mutators.remove('regulations', foundIndex)
    }
  }

  const revertToDefault = () => {
    const defaultRegulations = OTHER_GEO_SPECIFIC_REGULATION_NAMES.map((name, index) => ({
      actual: '',
      conformingStatus: '',
      required: '',
      name,
      id: ObjectID().toString(),
      index,
    }))

    const unitsIndex = defaultRegulations.findIndex(
      regulation => regulation.name === OTHER_GEO_DEFAULT_REGULATION.PERMITTED_UNITS
    )
    defaultRegulations[unitsIndex].actual = residentialUnits
    const lotSizeIndex = defaultRegulations.findIndex(
      regulation => regulation.name === OTHER_GEO_DEFAULT_REGULATION.MIN_LOT_SIZE
    )
    defaultRegulations[lotSizeIndex].actual = siteArea
    form.change('regulations', [...defaultRegulations])
  }

  const regulationRows = getRegulationRows()
  const columns = getTableColumns()

  return (
    <Grid container spacing={2} direction="column">
      <Grid container item direction="row" justify="flex-end">
        <Grid item justify="flex-end">
          <CheckboxWithLabel name="showRegulationsActualColumn" label="Show Actual Column" />
        </Grid>
        <Grid item>
          <Button
            onClick={addRegulation}
            data-qa="add-btn"
            variant="contained"
            size="small"
            sx={{ marginBottom: '8px' }}
          >
            Add Regulation
          </Button>
        </Grid>
        {!isNYCReport && (
          <Grid item>
            <Button
              onClick={revertToDefault}
              data-qa="revert-btn"
              variant="contained"
              size="small"
              sx={{ marginLeft: '8px' }}
            >
              Revert to Default
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item>
        <RowBasedTable
          id="bulk-regulations"
          columns={columns}
          rows={regulationRows}
          onRowUpdate={onRowUpdate}
          onManyRowsUpdate={noop}
          getCustomColumnConfig={getCustomColumnConfig}
          autoSizeColumns
          hideIndexColumn
          onColumnDragEnd={noop}
          onRowsDragEnd={noop}
          onColumnDelete={noop}
          onColumnUpdate={noop}
          onRowDelete={onRowDelete}
          autoSizeStrategy={{
            type: 'fitCellContents',
          }}
        />
      </Grid>
    </Grid>
  )
}
